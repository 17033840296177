import * as actionTypes from './actionTypes';
import axios from '../../axios-api';

export const authSuccess = (userData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      user: userData,
    }
  }
}

export const logout = () => {
console.log('logout');
  return {
    type: actionTypes.AUTH_LOGOUT,
  }
}

export const logoutApi = () => {
  console.log('logoutApi');
  return dispatch => {
    axios.get('/logout').then(res => {
console.log(res.data);
      dispatch(logout());
    }).catch(err => {
      if(err.response.data) {
console.log(err.response);
      }
      dispatch(logout());
    });
  }
}

export const checkState = () => {
  return dispatch => {
    axios.get('/me').then(res => {
console.log(res.data);
      if(res.data.user) {
        dispatch(authSuccess(res.data.user));
      }
    }).catch(err => {
      if(err.response.data) {
console.log(err.response);
      }
    });
    //dispatch(logout());
  }
}

import * as actions from '../actions/actionTypes';

const initialState = {
  user: null,
}

const reducer = (state = initialState, action) => {
  if (action.type === actions.AUTH_SUCCESS) {
      return {
        ...state,
        user: action.payload.user
      }
  }
  if (action.type === actions.AUTH_LOGOUT) {
      return {
        ...state,
        user: null
      }
  }

  return state;
}

export default reducer;

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginForm from '../LoginForm/LoginForm';

const Login = props => {
  return (
    <Container>
      <Row>
        <Col md={{span: 4, offset: 4}}>
          <LoginForm/>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;

import './Footer.scss';

const Footer = props => {
  return (
    <footer className='footer'>
      <div className='text-right credits'>
        Panel Klienta 3.0, wykonanie <a href='http://mdpdesign.pl' target='_blank' rel='noreferrer'>mdpdesign.pl</a>
      </div>
    </footer>
  );
}

export default Footer;

import {useState,useEffect} from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import LoginPage from './components/Pages/Login';
import LogoutPage from './components/Pages/Logout';
import DashboardPage from './components/Pages/Dashboard';
import UsersPage from './components/Pages/Users';
import FilesPage from './components/Pages/Files';
import MessagesPage from './components/Pages/Messages';
import CustomerfilesPage from './components/Pages/Customerfiles';
import ReportsPage from './components/Pages/Reports';
import CalendarPage from './components/Pages/Calendar';
import NewsPage from './components/Pages/News/News';
import HelpPage from './components/Pages/Help';
import './App.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTachometerAlt, faUsers, faCloudDownloadAlt, faEnvelope, faChartBar, faCalendarAlt, faQuestionCircle,
  faBars, faAngleDoubleRight, faSignInAlt, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faCopy, faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

//add font awesome icons to library
library.add(fab,
  faTachometerAlt, faUsers, faCloudDownloadAlt, faEnvelope, faChartBar, faCalendarAlt, faQuestionCircle, faBars, faAngleDoubleRight, faSignInAlt, faPlusSquare,
  faCopy, faNewspaper
);

const App = props => {
  const {onCheckAuthState} = props;

  useEffect(() => {
    onCheckAuthState();
  }, [onCheckAuthState])

  //DEBUG
  //login
  let content = (
    <div className="App">
      <LoginPage/>
      <Footer/>
    </div>
  );

  if(props.user) {
    content = (
      <div className="App">
        <Header/>
        <main className='content'>
          <Switch>
            <Route path="/login" exact component={LoginPage}/>
            <Route path="/logout" exact component={LogoutPage}/>
            <Route path="/dashboard" exact component={DashboardPage}/>
            <Route path="/users" component={UsersPage}/>
            <Route path="/files" component={FilesPage}/>
            <Route path="/messages" component={MessagesPage}/>
            <Route path="/customerfiles" component={CustomerfilesPage}/>
            <Route path="/reports" component={ReportsPage}/>
            <Route path="/calendar" component={CalendarPage}/>
            <Route path="/news" component={NewsPage}/>
            <Route path="/help" component={HelpPage}/>
            <Redirect to="/dashboard"/>
          </Switch>
        </main>
        <Footer/>
      </div>
    );
  }

  return content;
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCheckAuthState: () => dispatch(actions.checkState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

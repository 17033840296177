import { useState, useRef, useEffect } from 'react';
import axios from '../../../axios-api';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PageTitle from '../../Layout/PageTitle';
import NewsList from '../../News/NewsList';
import Loader from '../../Loader/Loader';
import { Editor } from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//const newsData = [];
let newsData = [
  {
    id: 5,
    author_id: 1,
    title: 'Witamy w ulepszonej odsłonie Panelu Klienta Biura Rachunkowego PROFIT.',
    content: '<p>&nbsp;</p>\r\n<p>Dodaliśmy tablicę ogłoszeń, system status&oacute;w dla dokument&oacute;w oraz nowy system pomocy.</p>\r\n<p><strong>Tablica ogłoszeń </strong>będzie służyła do przekazywania kr&oacute;tkich informacji z zakresu wsp&oacute;łpracy z biurem bądź najważniejszch zmian przepis&oacute;w.</p>\r\n<p><strong>System status&oacute;w</strong> pozwoli Państwu na lepsze zarządzanie informacjami od naszego biura rachunkowego.</p>\r\n<p><strong>System pomocy </strong>pozwoli na dostarczanie w usystematyzowany spos&oacute;b rozbudowanych informacji dotyczących wsp&oacute;łpracy z biurem, wzor&oacute;w przydatnych dokument&oacute;w oraz powiadomień o zmianach ważnych dla przedsiębiorc&oacute;w.</p>\r\n<p>W razie problem&oacute;w z działaniem Panelu Klienta zalecamy wyczyszczenie ciasteczek w przeglądarce.</p>\r\n<p>Aktualizacja dała nam nowe możliwości, jednak uzupełnienie zawartości systemu pomocy zajmie nam trochę czasu, liczymy na wyrozumiałość.</p>',
    bgcolor: '#33dd6f',
    created: '2020-02-02 14:06:38',
    modified: '2020-02-02 14:06:52',
  },
  {
    id: 2,
    author_id: 1,
    title: 'To jest test 1',
    content: 'Testowa treść',
    bgcolor: '#ff0000',
    created: '2020-02-02 14:06:38',
    modified: '2020-02-02 14:06:52',
  },
  {
    id: 4,
    author_id: 1,
    title: 'To jest test 2',
    content: 'Testowa treść',
    bgcolor: '#ff0000',
    created: '2020-02-02 14:06:38',
    modified: '2020-02-02 14:06:52',
  },
  {
    id: 6,
    author_id: 1,
    title: 'To jest test 3',
    content: 'Testowa treść',
    bgcolor: '#ff0000',
    created: '2020-02-02 14:06:38',
    modified: '2020-02-02 14:06:52',
  },
];

const News = props => {
  const [newsListState, setNewsListState] = useState([]);

  const editorRef = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  //const [selectedNews, setSelectedNews] = useState(null);
  
  const [showNewsDeleteConfirmationState, setShowNewsDeleteConfirmationState] = useState(false);
  const [newsIdState, setNewsIdState] = useState(null);
  const [newsColorState, setNewsColorState] = useState(null);
  const [newsTitleState, setNewsTitleState] = useState(null);
  const [newsContentState, setNewsContentState] = useState(null);

  useEffect(() => {
    setShowLoader(true);
    axios.get(`/api/news`)
    .then(res => {
//console.log(res.data['hydra:member'][0]['@id']);
      const items = [];
      //dodanie do listy
      res.data['hydra:member'].forEach(item => {
        item.id = item['@id'];
        items.push(item);
      });
      setNewsListState(items);
    }).catch(err => {
      if(err.response) {
console.log(err.response);
      }
    }).finally(() => {
      setShowLoader(false);
    });

  }, []);

  const editNewsHandler = (id) => {
console.log(`id newsa: ${id}`);
    const currentNews = newsListState.filter(item => item.id === id)[0];
console.log(`selected:`, currentNews);
    setNewsIdState(id);
    setNewsColorState(currentNews.bgcolor);
    setNewsTitleState(currentNews.title);
    setNewsContentState(currentNews.content);
    setShowEditModal(true);
  }

  const addNewsHandler = () => {
    setNewsIdState(null);
    setNewsColorState('#000000');
    setNewsTitleState(null);
    setNewsContentState(null);
    setShowEditModal(true);
  }

  const closeEditModalHandler = () => {
    setShowEditModal(false);
    setShowNewsDeleteConfirmationState(false);
  }

  const saveNewsHandler = () => {
    if(newsIdState !== null) {
      //edycja
      const updatedItem = {
        bgcolor: newsColorState,
        title: newsTitleState,
        content: editorRef.current.getContent(),
      };
      axios.put(`${newsIdState}`, updatedItem)
      .then(res => {
        //update na liscie
        setNewsListState(prev => {
          const updatedList = [...prev];
          const newsItem = updatedList.find(item => item.id === newsIdState);
          newsItem.bgcolor = updatedItem.bgcolor;
          newsItem.title = updatedItem.title;
          newsItem.content = updatedItem.content;
          return updatedList;
        });
      }).catch(err => {
        if(err.response) {
console.log(err.response);
        }
      }).finally(() => {
        //setLoadingState(false);
      });
    } else {
      //dodanie nowego
      const date = new Date();
      const newItem = {
        author: '/api/users/1',
        bgcolor: newsColorState,
        title: newsTitleState,
        content: editorRef.current.getContent(),
        created: date.toString(),
        modified: date.toString(),
      };
      axios.post(`/api/news`, newItem)
      .then(res => {
        //dodanie do listy
        newItem.id = res.data['@id'];
        setNewsListState(prev => [newItem, ...prev]);
      }).catch(err => {
        if(err.response) {
console.log(err.response);
        }
      }).finally(() => {
        //setLoadingState(false);
      });
    }

    setShowEditModal(false);
  }

  const deleteNewsHandler = () => {
    if(showNewsDeleteConfirmationState) {
      //usuniecie
      axios.delete(`${newsIdState}`)
      .then(res => {
        //usuniecie z listy
        setNewsListState(prev => prev.filter(item => item.id !== newsIdState));
        setShowEditModal(false);
        setShowNewsDeleteConfirmationState(false);
      }).catch(err => {
        if(err.response) {
console.log(err.response);
        }
      }).finally(() => {
        //setLoadingState(false);
      });
    } else {
      //wyświetlenie potwierdzenia
      setShowNewsDeleteConfirmationState(true);
    }
  }

  const actions = [
    <Button variant="secondary" onClick={closeEditModalHandler} key='close'>Zamknij</Button>
  ];
  if(newsIdState !== null) {
    actions.unshift(
      <Button variant="danger" onClick={deleteNewsHandler} key='delete'>Usuń</Button>
    );
  }
  if(!showNewsDeleteConfirmationState) {
    actions.unshift(
      <Button variant="primary" onClick={saveNewsHandler} key='save'>Zapisz</Button>
    );
  }
  
  let formContent = null;
  if(!showNewsDeleteConfirmationState) {
    formContent = <Form>
      <Form.Group controlId="formColor">
        <Form.Label>Kolor oznaczenia</Form.Label>
        <Form.Control type="color" value={newsColorState} onChange={e => setNewsColorState(e.target.value)} />
      </Form.Group>
      <Form.Group controlId="formTitle">
        <Form.Label>Tytuł</Form.Label>
        <Form.Control type="text" value={newsTitleState} onChange={e => setNewsTitleState(e.target.value)} />
      </Form.Group>
      <Form.Group controlId="formContent">
        <Form.Label>Treść</Form.Label>
        {/* <Form.Control as="textarea"  value={newsContentState} onChange={e => setNewsContentState(e.target.value)} /> */}
        <Editor
         tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce/tinymce.min.js'}
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue={newsContentState}
         init={{
           language: 'pl',
           height: '50vh',
           menubar: false,
           plugins: [
             'lists advlist link',
           ],
           toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link',
           advlist_bullet_styles: 'square',
		       advlist_number_styles: 'lower-alpha,lower-roman,upper-alpha,upper-roman',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
           target_list: [
            {title: 'Aktualne okno', value: ''},
            {title: 'Nowe okno', value: '_blank'},
          ]
         }}
        />
      </Form.Group>
    </Form>;
  }

  return (
    <>
      <PageTitle title="Aktualności"/>
      <div className="portlet portlet-default">
        <div className="portlet-heading">
          <div className="portlet-title"><h4>Pozycje</h4></div>
          <div className="portlet-widgets">
            <Button className="btn btn-sm" onClick={addNewsHandler}>
              <FontAwesomeIcon icon="plus-square" size="1x" className="mr-2" />Dodaj
              </Button>
          </div>
          <div className='clearfix'></div>
        </div>
        <div className="portlet-body">
          {showLoader ? <Loader/> :
          <NewsList items={newsListState} editNews={editNewsHandler}/>}
        </div>
      </div>
      <Modal size="lg" show={showEditModal} onHide={closeEditModalHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{newsIdState === null ? 'Nowa pozycja' : 'Edycja pozycji'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formContent}
          <Alert show={showNewsDeleteConfirmationState} variant='danger'><b>UWAGA</b> Operacja jest nieodwracalna.<br/>Czy na pewno usunąć pozycję?</Alert>
        </Modal.Body>
        <Modal.Footer>
          {actions}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default News;

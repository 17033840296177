import {useState,useEffect} from 'react';
import axios from '../../axios-api';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LoginForm.scss';

const LoginForm = props => {
  const [loginState, setLoginState] = useState('');
  const [passState, setPassState] = useState('');
  const [errorState, setErrorState] = useState(null);
  const [loadingState, setLoadingState] = useState(false);

  const onSubmitHandler = event => {
    event.preventDefault();

    if(loginState.length === 0 || passState.length === 0) return;

console.log({login: loginState, pass: passState});
    setErrorState(false);
    setLoadingState(true);

    axios.post('/login', {
      email: loginState,
      password: passState
    }).then(res => {
console.log(res.headers.location);
      props.onAuthCheckState();
    }).catch(err => {
      if(err.response.data) {
console.log(err.response);
        setErrorState(true);
      }
    }).finally(() => {
      setLoadingState(false);
    });
  }

  const onChangeLoginHandler = event => {
    setLoginState(event.target.value);
    setErrorState(false);
    //console.log(event.target.value);
  }

  const onChangePassHandler = event => {
    //console.log(event.target.value);
    setPassState(event.target.value);
    setErrorState(false);
  }

  return (
    <>
      <div className="login-banner text-center">
        <h3>PROFIT - Panel Klienta</h3>
      </div>
      <Card className='login-card'>
        <Card.Header>
          <FontAwesomeIcon icon="sign-in-alt" size="2x" className="mr-2" />
          Logowanie
        </Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group controlId="formEmail">
              <Form.Control type="email" placeholder="Użytkownik" value={loginState} onChange={onChangeLoginHandler} />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Control type="password" placeholder="Hasło" value={passState} onChange={onChangePassHandler} />
            </Form.Group>
            <Alert show={errorState} variant='danger'>Nieprawidłowy użytkownik lub hasło.</Alert>
            <Button variant="primary" type="submit" size="lg" block className="mt-4" disabled={loadingState}>
              {loadingState ? 'Czekaj...' : 'Zaloguj'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onAuthCheckState: () => dispatch(actions.checkState()),
  }
}
export default connect(null, mapDispatchToProps)(LoginForm);

import {Redirect} from 'react-router';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';

const Logout = props => {
  const {onLogout} = props;

  useEffect(() => {
console.log('logout');
    onLogout();
  }, [onLogout]);

  return (
    <Redirect to="/"/>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logoutApi()),
  }
}
export default connect(null, mapDispatchToProps)(Logout);

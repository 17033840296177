import './PageTitle.scss';

const PageTitle = props => {
  const title = props.title ? props.title : "Tytuł strony";

  return (
    <div className="page-title">
      <h1>{title}</h1>
    </div>
  );
}

export default PageTitle;

import {useState} from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SidebarNav from './SidebarNav';
import logo from '../../assets/images/logo_sm.png';
import './Header.scss';

const Header = props => {
  const [navbarExpandedState, setNavbarExpandedState] = useState(false);

  const navbarToggleHandler = expanded => {
console.log(`Navbar expanded=${expanded}`);
    setNavbarExpandedState(expanded);
  }

  return (
    <>
      <Navbar bg="profit-navbar" expand="lg" className="navbar"
        defaultExpanded={navbarExpandedState} onToggle={navbarToggleHandler}>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <NavDropdown title="geestaq (rafal@mdpdesign.pl)" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Zmiana hasła</NavDropdown.Item>
              <LinkContainer to="/logout">
                <NavDropdown.Item>Wyloguj się</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <SidebarNav expanded={navbarExpandedState}/>
    </>
  );
}

export default Header;

import Button from 'react-bootstrap/Button';
import PageTitle from '../Layout/PageTitle';

const Dashboard = props => {
  return (
    <>
      <PageTitle title="Pulpit"/>
      <Button variant="secondary">To jest test</Button>
    </>
  );
}

export default Dashboard;

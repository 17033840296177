import {NavLink} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SidebarNav.scss';

const SidebarNav = props => {
  let navbarClass = 'navbar-collapse collapse';
  if(props.expanded) navbarClass += ' show';

  return (
    <nav className='navbar-side' role="navigation">
      <div className={navbarClass}>
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <NavLink
                to='/dashboard'
                exact={true}
                className='navlink'>
                <FontAwesomeIcon icon="tachometer-alt" size="2x" className="mr-2" />
                Pulpit
              </NavLink>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" className="navlink" eventKey="1">
                <FontAwesomeIcon icon="users" size="2x" className="mr-2" />
                Użytkownicy
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <ul>
                  <li>
                    <NavLink
                      to='/users'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Przeglądaj
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/users'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Grupy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/users'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Operacje użytkowników
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/users'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Dodaj
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/users'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Przydziel klientów
                    </NavLink>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" className="navlink" eventKey="2">
                <FontAwesomeIcon icon="cloud-download-alt" size="2x" className="mr-2" />
                Pliki udostępnione
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <ul>
                  <li>
                    <NavLink
                      to='/files'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Przeglądaj
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/files'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Dodaj
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/files'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Kategorie
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/files'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Statusy
                    </NavLink>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <NavLink
                to='/messages'
                exact={true}
                className='navlink'>
                <FontAwesomeIcon icon="envelope" size="2x" className="mr-2" />
                Wiadomości
              </NavLink>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" className="navlink" eventKey="4">
                <FontAwesomeIcon icon={['far', 'copy']} size="2x" className="mr-2" />
                Dokumenty klientów
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <ul>
                  <li>
                    <NavLink
                      to='/customerfiles'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Przeglądaj
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/customerfiles'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Przeglądaj wszystkie
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/customerfiles'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Kategorie
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/customerfiles'
                      exact={true}
                      className='navsublink'>
                      <FontAwesomeIcon icon="angle-double-right" className="mr-2" />
                      Statusy
                    </NavLink>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <NavLink
                to='/reports'
                exact={true}
                className='navlink'>
                <FontAwesomeIcon icon="chart-bar" size="2x" className="mr-2" />
                Raporty
              </NavLink>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <NavLink
                to='/calendar'
                exact={true}
                className='navlink'>
                <FontAwesomeIcon icon="calendar-alt" size="2x" className="mr-2" />
                Kalendarz
              </NavLink>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <NavLink
                to='/news'
                exact={true}
                className='navlink'>
                <FontAwesomeIcon icon={['far', 'newspaper']} size="2x" className="mr-2" />
                Aktualności
              </NavLink>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <NavLink
                to='/help'
                exact={true}
                className='navlink'>
                <FontAwesomeIcon icon="question-circle" size="2x" className="mr-2" />
                Pomoc
              </NavLink>
            </Card.Header>
          </Card>
        </Accordion>
      </div>
    </nav>
  );

  return (
    {/*
    <nav className='navbar-side' role="navigation">
      <div className={navbarClass}>
        <ul className='side-nav'>
          <li>
            <NavLink
              to='/'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon="tachometer-alt" size="2x" className="mr-2" />
              Pulpit
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon="users" size="2x" className="mr-2" />
              Użytkownicy
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon="cloud-download-alt" size="2x" className="mr-2" />
              Pliki udostępnione
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon="envelope" size="2x" className="mr-2" />
              Wiadomości
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon={['far', 'copy']} size="2x" className="mr-2" />
              Dokumenty klientów
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon="chart-bar" size="2x" className="mr-2" />
              Raporty
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon="calendar-alt" size="2x" className="mr-2" />
              Kalendarz
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon={['far', 'newspaper']} size="2x" className="mr-2" />
              Aktualności
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/aa'
              exact={true}
              className='navlink'>
              <FontAwesomeIcon icon="question-circle" size="2x" className="mr-2" />
              Pomoc
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
    */}
  )
}

export default SidebarNav;

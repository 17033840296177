import Button from 'react-bootstrap/Button';
import PageTitle from '../Layout/PageTitle';

const Files = props => {
  return (
    <>
      <PageTitle title="Pliki udostępnione"/>
      <Button variant="secondary">To jest test</Button>
    </>
  );
}

export default Files;

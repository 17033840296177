import Button from 'react-bootstrap/Button';
import './NewsList.scss';

const NewsList = props => {
    let data = <div>Brak pozycji do wyświetlenia</div>;
    if(props.items.length > 0) {
        data = <div className='container-news'>
            <ul>
                {props.items.map(item => <li key={item.id}>
                    <div className='news-item' style={{borderLeft: `5px solid ${item.bgcolor}`}}>
                        <div className='news-date'>{item.created}</div>
                        <div className='news-title'>{item.title}</div>
                        <div className='news-content' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                        <div className='news-actions'>
                            <Button variant='primary' onClick={() => props.editNews(item.id)}>Edytuj</Button>
                        </div>
                    </div>
                </li>)}
            </ul>
        </div>; 
    }

    return data; 
}

export default NewsList;
import Button from 'react-bootstrap/Button';
import PageTitle from '../Layout/PageTitle';

const Calendar = props => {
  return (
    <>
      <PageTitle title="Kalendarz"/>
      <Button variant="secondary">To jest test</Button>
    </>
  );
}

export default Calendar;
